<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.creditRefundReport')">
      <form>
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
            {{ $t("message.year") }} :
            <br />
            <b-form-select v-model="timeSel" :options="language== 'th'? timeOptTH: timeOptEN" v-on:change="selectTime" />
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3">
            {{ $t('message.machineIdFarm') }} :
            <br />
            <model-list-select :list="machineData" v-model="searchData.machineId" option-value="machineId"
              option-text="name" placeholder="เลือกรหัสร้าน"></model-list-select>
            <!-- <multi-list-select :list="machineData" option-value="machineId" option-text="name" :selected-items="selectedMachine" :placeholder="$t('message.all')" @select="onSelectMachine"></multi-list-select> -->
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2">
            {{ $t("message.createBy") }} :
            <br />
            <div class="table-responsive">
              <b-form-input v-model="searchData.createBy" />
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-3">
            {{ $t('message.date') }} :
            <br />
            <div class="table-responsive">
              <date-time-picker v-model="searchData.datetime"></date-time-picker>
            </div>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2">
            <br />
            <b-btn class="col-sm-6 col-md-5 col-lg-5" variant="primary" v-on:click="searchFn({ excel: false })">
              <i class="fa fa-search"></i>
              &nbsp;{{ $t('message.search') }}
            </b-btn> &nbsp;
            <b-btn class="col-sm-6 col-md-5 col-lg-5" variant="success" v-on:click="searchFn({ excel: true })">
              <i class="icon-docs"></i>
              &nbsp;{{ $t("message.export") }}
            </b-btn>
          </div>
        </div>
      </form>
      <div class="table-responsive">
        <table class="table-modal">
          <thead style="text-align-last: center">
            <tr>
              <th>{{ $t('message.date') }}</th>
              <th>{{ $t('message.machineIdFarm') }}</th>
              <th>{{ $t('message.refNumber') }}</th>
              <th>{{ $t('message.note') }}</th>
              <th>{{ $t('message.paymentType') }}</th>
              <th style="text-align-last: right">{{ $t('message.amount') }}</th>
              <th style="text-align-last: right">{{ $t('message.oldCredit') }}</th>
              <th style="text-align-last: right">{{ $t('message.all') }}</th>
              <th>{{ $t('message.createBy') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index, key) in rowData.rows">
              <tr style="text-align: center">
                <td style="min-width: 150px;">{{ item.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}</td>
                <td>{{ item.machineId }}</td>
                <td style="min-width: 140px;">{{ item.refNumber }}</td>
                <td style="min-width: 260px; text-align: left">{{ item.details.note }}</td>
                <td>
                  <template v-if="item.type === 'addCredit'">
                    <span class="badge badge-success" style="font-size: 100%">{{ $t('message.addCredit') }}</span>
                  </template>
                  <template v-else>
                    <span class="badge badge-danger" style="font-size: 100%">{{ $t('message.deductCredit') }}</span>
                  </template>
                </td>
                <td style="text-align: right; min-width: 70px;">{{ item.type === 'addCredit' ? '+' : '-' }} {{
                  Number(item.price ? item.price : item.acceptorMoney).toLocaleString()
                }}</td>
                <td style="text-align: right; min-width: 70px;">{{ Number(item.creditCMBefore).toLocaleString() }}</td>
                <td style="text-align: right; min-width: 70px;">{{ Number(item.creditCMAfter).toLocaleString() }}</td>
                <td>{{ item.details.createBy }}</td>
              </tr>
            </template>
          </tbody>
        </table>
        <br />
        <b-pagination size="md" :total-rows="totalRows" v-model="pagination" :per-page="itemperPage"
          @input="pageChanged(pagination)"></b-pagination>
        <!-- <b-pagination
          size="md"
          :total-rows="totalRows"
          v-model="pagination"
          :per-page="itemperPage"
          @input="pageChanged(pagination)"
        ></b-pagination>-->
      </div>
    </b-card>
  </div>
</template>


<script>
import moment from 'moment'
import FileSaver from 'file-saver'
import webServices from '../../script'
// import DatePicker from '../../custom_modules/vue2-datepicker'
import DateTimePicker from "../../components/DateTimePicker/Custom";

import 'vue2-datepicker/index.css'
// import DatePicker from 'vue2-datepicker'
// import 'vue2-datepicker/index.css'
import { ModelListSelect, MultiListSelect } from '../../custom_modules/search-select'

export default {
  name: 'Reports',
  components: {
    MultiListSelect,
    ModelListSelect,
    DateTimePicker
  },
  data() {
    return {
      api: {},
      timeSel: 0,
      timeOptTH: [
        { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
        { value: 1, text: '<2022'}
      ],
      timeOptEN: [
        { value: 0, text: '2022 To This Year' },
        { value: 1, text: '<2022'}
      ],
      language: window.localStorage.getItem("language"),
      rowData: [],
      modalData: {},
      now: new Date(),
      machineData: [],
      searchData_export: {},
      searchData: {
        datetime: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],
        // datetime: [moment(moment().toDate().setHours(0)).toDate(), moment(moment().toDate().setHours(23)).toDate()],
        id: '',
        machineId: '',
        deviceId: '',
        productId: '',
        qty: '',
        price: '',
        acceptorMoney: '',
        changerMoney: '',
        status: '',
        refNumber: '',
        type: ['credit', 'addCredit', 'deductCredit'],
        paymentType: ['REFUND', 'deductCredit'],
        page: 1,
        rows: 20
      },
      newReportData: {},
      selectedMachine: [],
      status: [
        { value: 'SUCCESS', text: 'แก้ไขแล้ว' },
        { value: 'PROCESSING', text: 'กำลังดำเนินการ' },
        { value: 'RECEIVED', text: 'ได้รับแจ้งปัญหาแล้ว' }
      ],
      options: [
        { value: true, text: 'ในประกัน' },
        { value: false, text: 'นอกประกัน' }
      ],
      totalRows: 0,
      itemperPage: 20,
      pagination: 1,
      branch: '',
      startDate: '',
      endDate: '',
    }
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },

    pervious7day(emit) {
      console.log(emit)
      emit([moment().subtract(7, 'days').toDate(), moment().toDate()])
    },

    pervious1month(emit) {
      emit([moment().subtract(30, 'days').toDate(), moment().toDate()])
    },

    handleInput(e) {
      this.date = e
      this.$emit('input', this.date)
    },

    getMachine() {
      this.$Progress.start()
      webServices
        .getMachineList(window.localStorage.getItem('userId'))
        .then(res => {
          this.$Progress.finish()
          this.machineData = res.data
          // console.log(res.data)
        })
        .catch(err => {
          this.$Progress.fail()
          console.log('error @get Macinhe')
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },

    showAlert_ChooseOneBranch() {
      if (window.localStorage.getItem("language") == 'th') {
        var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
        var text = 'ไม่สามารถเลือกเดือนได้มากกว่า 12 เดือน'
        var confirmButton = "ปิด"
      } else {
        var title = "Please select a new time again"
        var text = "Cannot select more than 12 months."
        var confirmButton = "Close"
      }

      this.$swal({
        title: title,
        text: text,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: confirmButton
      })
    },

    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },

    searchFn(opt = { excel: false }) {
      if (this.searchData.datetime !== '') {
        var timeEnd = moment(this.searchData.datetime[1]).format('HH:mm:ss')
        if (timeEnd === '00:00:00' || timeEnd === '12:00:00 AM') {
          this.searchData.datetime[1].setHours(23, 59, 59, 999)
        }
        this.fromdate = moment(this.searchData.datetime[0]).format('YYYY-MM-DD HH:mm:ss')
        this.todate = moment(this.searchData.datetime[1]).format('YYYY-MM-DD HH:mm:ss')

      } else {
        this.fromdate = ''
        this.todate = ''
      }

      if (this.searchData.type != 'washing') {
        this.transactionType = 'ALL'
      }

      var localStorageUser = JSON.parse(window.localStorage.getItem("users"))
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest

      // var dateStart = new Date(this.searchData.datetime[0])
      // var dateEnd = new Date(this.searchData.datetime[1])

      // if(this.monthDiff(dateStart, dateEnd) < 12){
      if (!opt.excel) {
        this.getTransactions()
      } else {
        if (this.rowData.rows.length > 0) {
          this.exportExcel()
        }
        // this.exportExcel()
      }
      // }else{
      //   console.log("เลือกเดือนได้สูงสุด 12 เดือน")
      //   this.showAlert_ChooseOneBranch() // เลือกเดือนได้สูงสุด 12 เดือน
      // }
    },
    getTransactions(page = 1) {
      this.$Progress.start()

      const rows = this.itemperPage
      const params = {
        ...this.searchData,
        page: page,
        rows: rows,
        from: this.fromdate,
        to: this.todate
      }

      if (Array.isArray(params.machineId)) {
        params.machineId = this.searchData.machineId.join(',')
      }

      if (Array.isArray(params.productId)) {
        params.productId = this.searchData.productId.join(',')
      }

      delete params.id
      delete params.datetime

      this.axios
        .get(`/transactions`, { params })
        .then(res => {
          this.$Progress.finish()
          this.rowData = res.data

          this.searchData_export.acceptorMoney = this.searchData.acceptorMoney
          this.searchData_export.changerMoney = this.searchData.changerMoney
          this.searchData_export.datetime = this.searchData.datetime
          this.searchData_export.datetimeRequest = this.searchData.datetimeRequest
          this.searchData_export.deviceId = this.searchData.deviceId
          this.searchData_export.id = this.searchData.id
          this.searchData_export.machineId = this.searchData.machineId
          this.searchData_export.page = this.searchData.page
          this.searchData_export.paymentType = this.searchData.paymentType
          this.searchData_export.price = this.searchData.price
          this.searchData_export.productId = this.searchData.productId
          this.searchData_export.qty = this.searchData.qty
          this.searchData_export.refNumber = this.searchData.refNumber
          this.searchData_export.rows = this.searchData.rows
          this.searchData_export.status = this.searchData.status
          this.searchData_export.type = this.searchData.type
          this.searchData_export.userConfiglanguage = this.searchData.userConfiglanguage
          this.searchData_export.userName = this.searchData.userName
          this.searchData_export.userRole = this.searchData.userRole
          this.searchData_export.userType = this.searchData.userType

          // this.rowData.rows.forEach((row, idx) => {
          //   if (row.transactionType == 'MAIN') {
          //     this.rowData.rows[idx].children = []
          //     this.rowData.rows[idx]._loading = false
          //   }
          // })

          this.totalRows = res.data.count
        })
        .catch(err => {
          this.$Progress.fail()
          console.log('error Orders')
          console.log(err)
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },

    onSelectMachine(items) {
      // console.log(items)
      this.selectedMachine = items
      this.searchData.machineId = this.selectedMachine.map(data => data.machineId)
      this.searchData.name = this.selectedMachine.map((data) => data.name.substr(9).replaceAll('\\r\\n', ''))
    },

    exportExcel() {

      // console.log('this.machineData', this.machineData)
      for (let i = 0; i < this.machineData.length; i++) {
        if (this.searchData_export.machineId === this.machineData[i].machineId) {
          const machineName = this.machineData[i].name
          this.searchData_export.name = machineName.substr(9).replaceAll('\\r\\n', '')
        }
      }

      // console.log('this.searchData_export', this.searchData_export)
      this.fromdate = this.searchData_export.datetime[0]
      this.todate = this.searchData_export.datetime[1]

      const params = {
        report: 'creditrefund',
        ...this.searchData_export,
        from: this.fromdate,
        to: this.todate,
      }
      this.$Progress.start()

      if (Array.isArray(params.machineId)) {
        params.machineId = this.searchData.machineId.join(',')
      }

      if (Array.isArray(params.productId)) {
        params.productId = this.searchData.productId.join(',')
      }

      delete params.id
      delete params.datetime

      this.axios({
        url: `/transactions/download`, // File URL Goes Here
        method: 'GET',
        params,
        responseType: 'blob'
      }).then(response => {
        if (this.searchData_export.machineId.length > 0) {
          this.branch = this.searchData_export.machineId
        } else {
          this.branch = '...'
        }

        FileSaver.saveAs(response.data, `Report_Refund_Credit_${this.branch}_date_${moment(this.fromdate).format('YYYY-MM-DD')}_to_${moment(this.todate).format('YYYY-MM-DD')}.xlsx`)
        this.$Progress.finish()
      })
    },
    pageChanged(PageNum) {
      this.getTransactions(PageNum)
    }
  },
  mounted() {
    this.getMachine()
    this.getTransactions()
  }
}
</script>